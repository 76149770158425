import { render, staticRenderFns } from "./DeclinedPaymentTableNav.vue?vue&type=template&id=2b981cde&scoped=true"
import script from "./DeclinedPaymentTableNav.vue?vue&type=script&lang=js"
export * from "./DeclinedPaymentTableNav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b981cde",
  null
  
)

export default component.exports