<template>
  <b-card>
    <b-container fluid class="p-0">
      <b-row>
        <b-col>
          <b-form-group
            label-size="sm"
            label-class="font-weight-bold m-0"
            label="За період"
          >
            <date-range-picker
              :start-date="filter.processedAtFrom.value"
              :end-date="filter.processedAtTo.value"
              v-on:start-date-change="changeProcessedAtFrom"
              v-on:end-date-change="changeProcessedAtTo"
            />
          </b-form-group>
        </b-col>
        <b-col />
        <b-col />
        <b-col />
      </b-row>
      <b-row class="pb-3">
        <b-col>
          <b-button v-if="isSubmitting" variant="secondary" disabled>
            <b-icon icon="circle-fill" animation="throb" scale="0.75" />
          </b-button>
          <b-button v-else variant="primary" @click="setDefaultFilter">
            Очистити
          </b-button>
        </b-col>
        <b-col class="text-right">
          <b-button-group>
            <b-button variant="primary" @click="doTestPayment">
              <b-icon-link45deg scale="0.75" /> Тестова оплата
            </b-button>
            <b-button variant="secondary" disabled>
              Загальна кількість: <b-badge variant="light">{{ total }}</b-badge>
            </b-button>
            <b-button
              :variant="canExport ? 'primary' : 'secondary'"
              :disabled="!canExport"
              @click="exportData"
            >
              <b-icon-download scale="0.75" /> Експорт
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>
    <declined-payment-table :items="declinedPaymentList" />
    <declined-payment-table-nav
      :total="total"
      :prop-per-page="perPage"
      :prop-current-page="currentPage"
      v-on:current-page-change="changeCurrentPage"
      v-on:per-page-change="changePerPage"
    />
  </b-card>
</template>

<script>
import api from "@/api/api";
import DateRangePicker from "@/components/DateRangePickerWrapper";
import DeclinedPaymentTable from "@/entity/DeclinedPayments/components/DeclinedPaymentTable";
import DeclinedPaymentTableNav from "@/entity/DeclinedPayments/components/DeclinedPaymentTableNav";
import moment from "moment/moment";
import { mapGetters } from "vuex";
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can("view declined payment")) next();
      else next({ name: "Page404" });
    });
  },
  name: "DeclinedPaymentList",
  components: {
    DateRangePicker,
    DeclinedPaymentTable,
    DeclinedPaymentTableNav,
  },
  data: () => {
    return {
      isSubmitting: false,
      filter: {},
      currentPage: 1,
      perPage: 50,
      total: 0,
      declinedPaymentList: [],
    };
  },
  computed: {
    ...mapGetters({
      me: "auth/me",
    }),
    canExport: function () {
      return (
        this.total > 0 &&
        this.me.role.name !== this.$stringConstants("ROLE_INSPECTOR")
      );
    },
  },
  methods: {
    changeProcessedAtFrom: function (v) {
      this.filter.processedAtFrom.value = v;
    },
    changeProcessedAtTo: function (v) {
      this.filter.processedAtTo.value = v;
      this.total = 0;
      this.changeCurrentPage(1);
    },
    changeCurrentPage: function (v) {
      this.$set(this, "currentPage", v);
      this.getDeclinedPaymentList();
    },
    changePerPage: function (v) {
      this.$set(this, "perPage", v);
      this.changeCurrentPage(1);
    },
    prepareFilterParams: function () {
      const filters = {};
      Object.entries(this.filter).forEach(([, filter]) => {
        if (
          filter.value !== null &&
          filter.value !== "" &&
          filter.value.length !== 0
        ) {
          filters[`filter[${filter.name}@${filter.type}]`] = filter.value;
        }
      });
      return {
        page: this.currentPage,
        per_page: this.perPage,
        ...filters,
      };
    },
    getDeclinedPaymentList: function () {
      this.declinedPaymentList = [];
      this.isSubmitting = true;
      api
        .getDeclinedPaymentList(this.prepareFilterParams())
        .then(({ data }) => {
          this.declinedPaymentList = data.data;
          this.currentPage = data.meta.current_page;
          this.perPage = data.meta.per_page;
          this.total = data.meta.total;
          this.isSubmitting = false;
        });
    },
    setDefaultFilter: function () {
      this.$set(this, "filter", {
        processedAtFrom: {
          name: "processed_at",
          type: "gte",
          value:
            this.me.role.name === this.$stringConstants("ROLE_INSPECTOR")
              ? moment("2022-09-01").startOf("day").toDate()
              : moment().subtract(1, "days").startOf("day").toDate(),
        },
        processedAtTo: {
          name: "processed_at",
          type: "lte",
          value: moment().toDate(),
        },
      });
      this.total = 0;
      this.changeCurrentPage(1);
    },
    doTestPayment: function () {
      api.getPaymentUrl().then((response) => window.open(response, "_blank"));
    },
    exportData() {
      api
        .exportDeclinedPayments("list", this.prepareFilterParams())
        .then(() => {
          this.$snotify.success("Експорт в обробці.");
        })
        .catch((error) => {
          this.$snotify.error("Помилка екcпорту операцій");
          this.errors = {
            code: error.response.status,
            description:
              error.response.status === 422
                ? error.response.data.errors
                : error.response.statusText,
          };
        });
    },
  },
  beforeMount() {
    this.setDefaultFilter();
  },
};
</script>

<style scoped></style>
