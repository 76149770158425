<template>
  <b-table
    show-empty
    :responsive="true"
    :hover="true"
    :bordered="true"
    :fields="fields"
    :items="items"
  >
    <template v-slot:cell(payer)="data">
      <b-link
        target="_blank"
        v-if="data.item.payer_counterpart_id"
        :to="{
          name: 'counterpart',
          params: { id: data.item.payer_counterpart_id },
        }"
      >
        {{ data.value }}
      </b-link>
      <span v-else>{{ data.value }}</span>
    </template>

    <!-- payer fullname -->
    <template v-slot:cell(payer_fullname)="data">
      {{ data.item.payer_last_name }}
      {{ data.item.payer_first_name }}
      {{ data.item.payer_middle_name }}
    </template>
    <!-- END: payer fullname -->

    <template v-slot:cell(payer_contract_number)="data">
      <b-link
        target="_blank"
        v-if="data.item.payer_contract_id"
        :to="{
          name: 'contract',
          params: { id: data.item.payer_contract_id },
        }"
      >
        {{ data.value }}
      </b-link>
      <span v-else>{{ data.value }}</span>
    </template>

    <template v-slot:cell(payee)="data">
      <b-link
        target="_blank"
        v-if="data.item.payee_counterpart_id"
        :to="{
          name: 'counterpart',
          params: { id: data.item.payee_counterpart_id },
        }"
      >
        {{ data.value }}
      </b-link>
      <span v-else>{{ data.value }}</span>
    </template>

    <template v-slot:cell(payee_fullname)="data">
      {{ data.item.payee_last_name }}
      {{ data.item.payee_first_name }}
      {{ data.item.payee_middle_name }}
    </template>

    <template v-slot:cell(payee_contract_number)="data">
      <b-link
        target="_blank"
        v-if="data.item.payee_contract_id"
        :to="{
          name: 'contract',
          params: { id: data.item.payee_contract_id },
        }"
      >
        {{ data.value }}
      </b-link>
      <span v-else>{{ data.value }}</span>
    </template>

    <template v-slot:cell(payer_card_hash)="data">
      <b-badge
        v-if="data.item.payer_card_hash"
        v-b-tooltip="data.item.payer_card_hash"
        :data-text="data.item.payer_card_hash"
        @click.stop.prevent="copyItem"
        class="cursor-pointer"
        variant="light"
      >
        {{ makeShortenedHash(data.item.payer_card_hash) }}
      </b-badge>
    </template>
    <template v-slot:cell(payee_card_hash)="data">
      <b-badge
        v-if="data.item.payee_card_hash"
        v-b-tooltip="data.item.payee_card_hash"
        :data-text="data.item.payee_card_hash"
        @click.stop.prevent="copyItem"
        class="cursor-pointer"
        variant="light"
      >
        {{ makeShortenedHash(data.item.payee_card_hash) }}
      </b-badge>
    </template>

    <!-- amount -->
    <template v-slot:cell(amount)="data">
      <b>{{ data.value }}</b>
    </template>
    <!-- END: amount -->

    <!-- Not Found -->
    <template #empty="scope">
      <div class="h4 text-center">Платежі не знайдено</div>
    </template>
    <!-- END: Not Found -->
  </b-table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment/moment";
export default {
  name: "DeclinedPaymentTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      contractPaymentMethodList: "dictionary/allContractPaymentMethods",
      operationNbuPaysystemList: "dictionary/allOperationNbuPaysystemList",
      operationDestinationCodeList: "dictionary/allOperationDestinationCode",
      financialModelList: "auth/meFinancialModels",
    }),
    fields: function () {
      const fields = [
        {
          key: "processed_at",
          label: "Дата",
          formatter: (v) =>
            v ? moment(v).format("DD.MM.YYYY HH:mm:ss") : null,
          tdClass: "text-right",
        },
        { key: "payer", label: "Платник" },
        { key: "payer_card_hash", label: "Хеш картки платника" },
        { key: "payer_fullname", label: "ПІБ платника" },
        {
          key: "payer_birthday",
          label: "Дата народження платника",
          formatter: (v) => (v ? moment(v).format("DD.MM.YYYY") : null),
          tdClass: "text-center",
        },
        { key: "payer_code", label: "РНОКПП платника" },
        { key: "payer_passport", label: "Паспорт платника" },
        { key: "payer_contract_number", label: "Договір з платником" },
        {
          key: "payer_is_public",
          label: "Платник ПЕП",
          formatter: (v) => (v ? "Так" : "Ні"),
        },
        { key: "payee", label: "Отримувач" },
        { key: "payee_card_hash", label: "Хеш картки отримувача" },
        { key: "payee_fullname", label: "ПІБ отримувача" },
        {
          key: "payee_birthday",
          label: "Дата народження отримувача",
          formatter: (v) => (v ? moment(v).format("DD.MM.YYYY") : null),
          tdClass: "text-center",
        },
        { key: "payee_code", label: "РНОКПП отримувача" },
        { key: "payee_passport", label: "Паспорт отримувача" },
        { key: "payee_contract_number", label: "Договір з отримувачем" },
        {
          key: "payee_is_public",
          label: "Отримувач ПЕП",
          formatter: (v) => (v ? "Так" : "Ні"),
        },
        {
          key: "amount",
          label: "Сума",
          formatter: (v) =>
            parseFloat(v || "0")
              .toFixed(2)
              .replace(".", ","),
          tdClass: "text-right",
        },
        { key: "currency", label: "Валюта" },
        {
          key: "operation_type_id",
          label: "Платіжний метод",
          formatter: (v) =>
            v
              ? this.contractPaymentMethodList.find((i) => i.id === v).name
              : null,
        },
        {
          key: "nbu_paysystem_code",
          label: "Платіжна система",
          formatter: (v) =>
            v
              ? this.operationNbuPaysystemList.find((i) => i.value === v).name
              : null,
        },
        { key: "paysystem_operation_id", label: "ID платіжної системи" },
        {
          key: "operation_destination_code_id",
          label: "Призначення платежу",
          formatter: (v) =>
            v
              ? this.operationDestinationCodeList.find((i) => i.id === v).name
              : null,
        },
        { key: "reason", label: "Метрика" },
      ];

      if (this.$auth.can(this.$stringConstants("PERMISSION_VIEW_OPERATION_ID")))
        fields.push({ key: "id", label: "ID" });

      if (
        this.$auth.can(
          this.$stringConstants("PERMISSION_VIEW_OPERATION_FINANCIAL_MODEL_ID")
        )
      )
        fields.push({
          key: "financial_model_id",
          label: "Профіль СПФМ",
          formatter: (v) =>
            v ? this.financialModelList.find((i) => i.id === v).name : null,
        });

      if (
        this.$auth.can(
          this.$stringConstants("PERMISSION_VIEW_OPERATION_TERMINAL_NAME")
        )
      )
        fields.push({ key: "terminal_name", label: "Термінал" });

      if (
        this.$auth.can(
          this.$stringConstants("PERMISSION_VIEW_OPERATION_INITIATOR_IP")
        )
      )
        fields.push({ key: "initiator_ip", label: "IP" });

      if (
        this.$auth.can(
          this.$stringConstants(
            "PERMISSION_VIEW_OPERATION_INITIATOR_ACCOUNT_NUMBER"
          )
        )
      )
        fields.push({ key: "initiator_account_number", label: "Аккаунт" });

      if (
        this.$auth.can(
          this.$stringConstants("PERMISSION_VIEW_OPERATION_ORDER_DESCRIPTION")
        )
      )
        fields.push({ key: "order_description", label: "Примітка" });

      return fields;
    },
  },
  methods: {
    copyItem(e) {
      navigator.clipboard.writeText(e.currentTarget.getAttribute("data-text"));
      this.$snotify.success("Скопійовано в буфер обміну");
    },
    makeShortenedHash(hash) {
      return hash.substring(0, 7) + ".." + hash.substring(hash.length - 2);
    },
  },
};
</script>

<style scoped></style>
