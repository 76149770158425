<template>
  <b-row>
    <b-col cols="auto" class="mr-auto p-3">
      <b-pagination
        v-model="currentPage"
        prev-text="Попередня"
        next-text="Наступна"
        hide-goto-end-buttons
        :per-page="perPage"
        :total-rows="total"
      />
    </b-col>

    <b-col cols="auto" class="ml-auto p-3">
      <b-form-group>
        <b-form-radio-group
          v-model="perPage"
          :options="options"
          button-variant="outline-primary"
          :buttons="true"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DeclinedPaymentTableNav",
  props: {
    propCurrentPage: {
      type: Number,
      required: true
    },
    propPerPage: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentPage: {
      get: function() {
        return this.propCurrentPage;
      },
      set: function(v) {
        this.$emit("current-page-change", v);
      }
    },
    perPage: {
      get: function() {
        return this.propPerPage;
      },
      set: function(v) {
        this.$emit("per-page-change", v);
      }
    }
  },
  data: function() {
    return {
      options: [
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "200", value: 200 }
      ]
    };
  }
};
</script>

<style scoped></style>
